import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
    return (
        <div className='footer-component'>
            <div className='footer-component-empty-div'></div>
            <div className='footer-component-content-div'>
                <img className='footer-logo' src='venmercados.png' />
                <div className='footer-content-wrapper'>
                    <div className='footer-quick-links-section'>
                        <p className='footer-quick-links-text'>Quick Links</p>
                        <div className='footer-quick-links-links'>
                            <Link to='/'><span className='footer-links'>Home</span></Link>
                            <Link to='/products'><span className='footer-links'>Products</span></Link>
                            <Link to='/features'><span className='footer-links'>Features</span></Link>
                            <Link to='/#contactUs'><span className='footer-links'>Contact Us</span></Link>
                        </div>
                    </div>
                    <div className='footer-official-details-section'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img className='footer-icons' src='location.svg' alt='location svg' />
                                    </td>
                                    <td>Shop no 15, Golden City Center, Beside Prozone Mall, Aurangabad - 431003, Maharashtra, India</td>
                                </tr>
                                <tr>
                                    <td><img className='footer-icons' src='phone.svg' alt='phone svg' /></td>
                                    <td>Phone No. 9607022226</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img className='footer-icons' src='email.svg' alt='email svg' />
                                    </td>
                                    <td>contact@mercados.in</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p className='footer-copyright-text'>&copy; Copyright, 2021 Vend-Mercados Pvt. Ltd. All rights reserved.</p>
            </div>
        </div>
    )
}
