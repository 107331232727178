import React from 'react'
import { Link } from 'gatsby'

export default function Navbar() {
    return (
        <nav className='navbar'>
            <div className='brand-title'>
                <img src='venmercados.png' className='nav-logo' alt='Mercados logo'></img>
            </div>
            <div className='toggle-button' onTouchStart={() => {
                const toggleButton = document.getElementsByClassName('toggle-button')[0];
                if (toggleButton) {
                    console.log('toggle button is ', toggleButton);
                    const navbarLinks = document.getElementsByClassName('navbar-links')[0];
                    console.log('navbar links is ', navbarLinks);
                    toggleButton.addEventListener('click', () => {
                        navbarLinks.classList.toggle('active');
                    });
                }
            }}>
                <span className='bar'></span>
                <span className='bar'></span>
                <span className='bar'></span>
            </div>
            <div className='navbar-links'>
                <ul>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/features'>Features</Link>
                    </li>
                    <li>
                        <Link to='/products'>Products</Link>
                    </li>
                    <li>
                        <Link to='/#contactUs'><button className='contact-us-button'>Contact Us</button></Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
