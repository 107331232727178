import React from 'react'
import Layout from "../components/Layout"
import * as styles from "../styles/products.module.css"
import ContentCards from "../components/ContentCards"

export default function Products() {
    
    return (
        <Layout>
            <div className='productsCover'>
                <ContentCards>
                    <img className='productsCoverImage' src='vending-machine.png' alt='vending machine' />
                </ContentCards>
                <div className='productsCoverContent'>
                    <p>
                        <span className='red-text'>Mercados</span> Vending Machines represent a completely new approach to machine design and has been exclusively styled
                    </p>
                    <p>
                        Introducing next generation vending machine that is super attractive with great user experience. It takes vending to a new level
                    </p>
                    <p>
                        It comes in a range of offerings and can dispense food items, stationery, electronic accessories, medicines and health products
                    </p>
                </div>
            </div>
            <div className={ styles.suitedProducts }>
                <p className={ styles.bestSuitedText }>Best suited for all products</p>
                <div className={ styles.suitedProductsList }>
                    <div>
                        <img className={ styles.suitedProductsImages } src='suited-products-snacks-beverages.png' alt='snacks and beverages' />
                        <div className={ styles.suitedProductsTags }>
                            Snacks and Beverages
                        </div>
                    </div>
                    <div>
                        <img className={ styles.suitedProductsImages } src='suited-products-fresh-food.png' alt='fresh food' />
                        <div className={ styles.suitedProductsTags }>
                            Fresh Food
                        </div>
                    </div>
                    <div>
                        <img className={ styles.suitedProductsImages } src='suited-products-medicine-and-health.png' alt='medicine and health products' />
                        <div className={ styles.suitedProductsTags }>
                            Medicines and Health Products
                        </div>
                    </div>
                    <div>
                        <img className={ styles.suitedProductsImages } src='suited-products-stationary.png' alt='office stationery' />
                        <div className={ styles.suitedProductsTags }>
                            Stationary
                        </div>
                    </div>
                </div>
            </div>

            {/* our vending machine model */}

            <div className='product-1'> 
                <div className='product-1-image'>
                    <img src='product-1.png' alt='product 1' />
                    <p>All Purpose Vending Machine</p>
                    <p>Rugged and Sturdy | Powered by Vendserves</p>
                </div>
                <div className='product-1-content'>
                    <ContentCards>
                        <p className='product-1-name green-text'>insert some good name here</p>
                    </ContentCards>
                    <div>
                        <p className='green-text product-1-brief'>Some good name by mercados represents a completely new approach to machine design and has been exclusively styled. It takes food vending to a new level. Some good name comes in a range of offerings and can offer fresh food items like fruits, sandwiches, milk and many more.</p>
                    </div>
                    <ContentCards>
                        <div className='product-1-features'>
                            <ul className='product-1-features-list'>
                                <li>Serves snacks, beverages and fresh food</li>
                                <li>Dimesions: 4ft x 4ft</li>
                                <li>Cooling temperature: Can cool upto 6 deg C</li>
                                <li>Power consumption: 4-5 units/day</li>
                            </ul>
                        </div>
                        <div>
                        </div>
                    </ContentCards>
                </div>
            </div>

            {/* make your workplace a little more happening */}

            <div className='better-workplace'>
                <div className='better-workplace-content'>
                    <p className='red-text'>
                        Make your workplace a little more happening
                    </p>
                    <p>
                        A little spice in your workplace, snacking , but all with good intentions and healthy inputs 
                    </p>
                </div>
                <ContentCards>
                    <div className='better-workplace-list'>
                        <p className='green-text'>
                            Eat Healthy at Work
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td><img src='tick-mark.png' alt='' /></td>
                                    <td className='better-workplace-list-td-text'>Satisfies hunger between meals</td>
                                </tr>
                                <tr>
                                    <td><img src='tick-mark.png' alt='' /></td>
                                    <td className='better-workplace-list-td-text'>Keeps energy level high and mind alert</td>
                                </tr>
                                <tr>
                                    <td><img src='tick-mark.png' alt='' /></td>
                                    <td className='better-workplace-list-td-text'>Prevents over-eating at meals</td>
                                </tr>
                                <tr>
                                    <td><img src='tick-mark.png' alt='' /></td>
                                    <td className='better-workplace-list-td-text'>Increases productivity among employees</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ContentCards>
            </div>

            {/* products more is always better */}

            <div className='more-is-better-section'>
                <p className='more-is-better-text green-text'>More is Always Better</p>

                <div className='final-features-grid'>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='available-24-7.png' alt='27x7 availability' />
                        <p className='green-text'>Available 24x7</p>
                        <p>Available at any time of the hour ( 24×7 ).Your bottom line improves with Higher Employee Productivity. Your Healthcare Costs come down.</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='hygiene-convenience.png' alt='hygenic' />
                        <p className='green-text'>Hygiene and Convenience</p>
                        <p>Offers fresh and tested food from trusted brands Increases the productivity among employees with Reduces the Healthcare costs and Controls the Lifestyle diseases.</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='no-app.png' alt='no need of any app' />
                        <p className='green-text'>Doesn't require downloading apps</p>
                        <p>Simple interface with Smart vend 2.0 powered by Vendserves. clould base operating system. Remotely Operated</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='just-plug-and-play.png' alt='plug and play' />
                        <p className='green-text'>Just Plug and Play</p>
                        <p>No Installation Required No complicated process Easy To Use 100% Approachable Hassle Free Buying</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='food-60-sec.png' alt='get your food in 60 seconds' />
                        <p className='green-text'>Get your product in 60 sec</p>
                        <p>Saves time for ordering/calling, available option to choose from and not recollect</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='timely-refill.png' alt='timely refills' />
                        <p className='green-text'>Timely Refill</p>
                        <p>No need to manage refilling, procurement, and supply Very affordable as compared to setting up a tuck shop or live kitchen </p>
                    </div>
                </div>
            </div>

        </Layout>
    )
}