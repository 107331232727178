import React from 'react'
import '../styles/global.css'

export default function ContentCards({ children }) {
    return (
        <div className='cards-container'>
            <div className='primary-dark-card'>
                <div className='primary-light-card'>
                    { children }
                </div>
            </div>
        </div>
    )
}
